import React from 'react';
// import { Carousel } from 'antd';
import { Link, graphql } from 'gatsby'
import SiteLayout from '@layouts/site-layout';
import BlogRoll from '../components/BlogRoll';
import HomeBlog from '../components/HomeBlog';
import ReactPlayer from 'react-player';
// import Video from '../components/Video';
import ModalVideo from 'react-modal-video';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal} from 'antd';




const settings = {
   dots: true,
   infinite: true,
   arrows: true,
   autoplay: false,
   speed: 3000,
   speed: 900,
   slidesToShow: 1,
   slidesToScroll: 1,
};

const testimonials = {
   dots: true,
   infinite: true,
   speed: 500,
   arrows: true,
   slidesToShow: 1,
   slidesToScroll: 1,
   adaptiveHeight: true,
};

const blog_slider = {
   dots: false,
   infinite: true,
   autoplay: true,
   speed: 500,
   arrows: true,
   slidesToShow: 4,
   slidesToScroll: 1,
   responsive: [
      {
         breakpoint: 767,
         settings: {
            slidesToShow: 2,
         }
      },
      {
         breakpoint: 480,
         settings: {
            slidesToShow: 1,
         }
      },
   ]
};

const our_partner = {
   dots: false,
   infinite: true,
   speed: 500,
   slidesToShow: 3,
   arrows: true,
   slidesToScroll: 1,
   responsive: [
      {
         breakpoint: 767,
         settings: {
            slidesToShow: 2,
         }
      },
      {
         breakpoint: 480,
         settings: {
            slidesToShow: 1,
         }
      },
   ]
};

export default class ome extends React.Component {
   constructor(props) {
      super(props) 
      this.state = {
         playingflg: false,
         videoUrl: 'https://youtu.be/dao2VVRYNE0',
         visible: false ,
        isOpen: false,
        videoId:'',
        playing:false,
        popup:true
      }
      
   }
   openModal = this.openModal.bind(this)
      onPlay = this.onPlay.bind(this)
   changeSlide = (ref) => {
      if (ref != 1) {
         this.setState({
            playingflg: false,
            videoUrl: ''
         })
      } else {
         this.setState({
            playingflg: false,
            videoUrl: 'https://youtu.be/dao2VVRYNE0'
         })
      }
   }
   
   componentDidMount(){
      var a = window.ChatGen || [];
      if (!a.loaded) {
          var s = document.createElement('script');
          s.type = "text/javascript";
          s.async = true;
          s.src = 'https://app.chatgen.ai/cmp/chat-widget/bot.js';
          var fs = document.getElementsByTagName('script')[0];
          fs.parentNode.insertBefore(s, fs);
          s.onload=function(){
              var yourKey = 'dnPALJkR';
              var widgetKey = {widget_key: yourKey};
              window.ChatGen.init(widgetKey);
          }
      }
  }
  showModal = () => {
   this.setState({
     visible: true,
   }); 
 };

 handleOk = e => {
   console.log(e);
   this.setState({
     visible: false,
   });
 };

 handleCancel = e => {
   console.log(e);
   this.setState({
     visible: false,
   });
};

handleCancelPopup = e => {
   console.log(e);
   this.setState({
     popup: false,
   });
};

onPlay (videoId) {
   this.setState({isOpen: true,
       videoId:videoId
 
   })
 }


 openModal (videoId) {
     console.log(videoId);
     
   this.setState({isOpen: true,
       playing:false,
                  videoId:videoId
               })
 }

 


   render() {
      const { dotPosition } = this.state
      let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
      e-learning software product that provides conceptual content delivery in a gamified format. Our
      team comes with deep experience and expertise gathered over many years of teaching and
      training students from various socio-economic strata of the country, preparing them for various
      reputed national and international competitive exams, and turning them into the best and
      brightest this country has ever seen.`
      let img = `img/stepapp_logo.png`
      return (
         <><Modal
            className="modal_header_popup"
            visible={this.state.popup}
            onOk={this.handleOk}
            footer={null}
            onCancel={this.handleCancelPopup}
         >
            <img src='img/stepapp_popup.jpg'/>
         </Modal><SiteLayout title="Home" description={description} image={img}>
               <div className="home_middle_sec top_margin_comn">
                  <div className="home_banner">
                     <Slider {...settings} afterChange={this.changeSlide} className="hm_carousel" ref={ref => {
                        console.log(ref);
                        // slider.current = ref;
                     } }>
                        <div className="home_slider">
                           <div className="slide_img">
                              {/* <img src="img/bn1.png" alt="banner-images" /> */}
                              <div className="banner_slide" style={{ background: "url('img/bn1.png') no-repeat center center /cover", height: "100vh" }}>
                              </div>
                              <div className="banner_text01 banner_text02 banner_hm">
                                 <h2>Apna Kal Khud Banao </h2>
                                 <p>Learn through Gamified content mapped to your school syllabus</p>
                                 {/* <Link className="banner_btn" to="/">Download The App</Link> */}
                                 <div className="app_btn">
                                    <a href="https://apps.apple.com/in/app/step-game-to-learn/id1472230553">
                                       <img src="img/apl_ply.png" alt="apple play store" />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.eduisfun.stepapplite">
                                       <img src="img/ggl_ply.png" alt="google play store" />
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="slide_img">
                           {/* <img src="img/bn2.png" alt="banner-images"/> */}
                           <div className="banner_slide" style={{ background: "url('img/bn2.png') no-repeat center center /cover", height: "100vh" }}> </div>
                           <div className="banner_text01 banner_text02 vdo_text">
                              <h2>Expert Mentorship </h2>
                              <p>Created by a team of 400+ IITians and Doctors, available in the language of your choice.</p>
                              {/* <Link to="/" className="banner_btn">Download The App</Link> */}
                              <div className="app_btn">
                                 <a href="https://apps.apple.com/in/app/step-game-to-learn/id1472230553">
                                    <img src="img/apl_ply.png" alt="apple play store" />
                                 </a>
                                 <a href="https://play.google.com/store/apps/details?id=com.eduisfun.stepapplite">
                                    <img src="img/ggl_ply.png" alt="google play store" />
                                 </a>
                              </div>
                           </div>
                           <div className="home_video">
                              <ReactPlayer url={this.state.videoUrl} width='100%' height='100%' playing={this.state.playingflg} />
                           </div>
                        </div>

                        <div className="slide_img">
                           {/* <img src="img/bn3.png" alt="banner-images"/> */}
                           <div className="banner_slide" style={{ background: "url('img/bn3.png') no-repeat center center /cover", height: "100vh" }}></div>
                           <div className="banner_text01 banner_text02 banner_hm">
                              <h2>Focus On Learning Outcomes </h2>
                              <p>Complete and comprehensive view of learning  outcomes</p>
                              {/* <Link to="/" className="banner_btn">Download The App</Link> */}
                              <div className="app_btn">
                                 <a href="https://apps.apple.com/in/app/step-game-to-learn/id1472230553">
                                    <img src="img/apl_ply.png" alt="apple play store" />
                                 </a>
                                 <a href="https://play.google.com/store/apps/details?id=com.eduisfun.stepapplite">
                                    <img src="img/ggl_ply.png" alt="google play store" />
                                 </a>
                              </div>
                           </div>
                        </div>

                        <div className="slide_img">
                           <div className="banner_slide" style={{ background: "url('img/banner05.jpg') no-repeat 100% /contain", height: "100vh" }}></div>
                        </div>

                     </Slider>
                  </div>

                  <div className="learning_outcomes">
                     <div className="container">
                        <div className="wow fadeInDown heding_lern">
                           Get Better Learning Outcomes <span>With A Unique Gamified </span> Learning App
                        </div>

                        <div className="row">
                           <div className="col-md-6">
                              <div className="wow fadeInLeft box1 box_grp">
                                 <div className="box_item">
                                    <span><img src="img/b1.png" alt="" /></span>
                                    <h3>Gamified Learning Experience</h3>
                                 </div>
                                 <p>Learn while you play Math and Science concepts in a fun and enjoyable manner through a unique gamified ecosystem.</p>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="wow fadeInRight box2 box_grp">
                                 <div className="box_item">
                                    <span><img src="img/b2.png" alt="" /></span>
                                    <h3>Created By Experts</h3>
                                 </div>
                                 <p>Content crafted by 400+ IITians and Doctors with 20+ years of teaching experience and gamified over the course of 5+ years.</p>
                              </div>
                           </div>
                        </div>

                        <div className="row">
                           <div className="col-md-6">
                              <div className="wow fadeInLeft box3 box_grp">
                                 <div className="box_item">
                                    <span><img src="img/b3.png" alt="" /></span>
                                    <h3>Mapped To Curriculum</h3>
                                 </div>
                                 <p>Concepts mapped to the school curriculum of major school boards (CBSE, ICSE, SSC) for seamless integration with existing pedagogy.
                                 </p>
                              </div>
                           </div>

                           <div className="col-md-6">
                              <div className="wow fadeInRight box4 box_grp">
                                 <div className="box_item">
                                    <span><img src="img/b4.png" alt="" /></span>
                                    <h3>Personalized Reporting </h3>
                                 </div>
                                 <p>Learning outcomes reported in-depth based on various parameters like speed, accuracy, and the number of attempts.</p>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>

                  <div className="smart_approach">
                     <h4 className="wow fadeIn commn_heading">The Smarter Approach To Learning</h4>
                     <ul>
                        <li>
                           <span className="wow fadeIn num_circle"><strong className="r_line">1</strong></span>
                           <div className="wow fadeInRight num_text">
                              <h3>We believe that students are naturally attuned to learning</h3>
                              <p>We believe that every student has an affinity towards learning that needs to be nurtured.</p>
                           </div>
                        </li>
                        <li className="img_text">
                           <span className="wow fadeIn num_circle"><strong className="l_line">2</strong></span>
                           <div className="wow fadeInLeft num_text">
                              <h3>They learn better when concepts are turned into games</h3>
                              <p>We use gamified content to make the student’s learning journey more engaging and rewarding.</p>
                           </div>
                        </li>
                        <li>
                           <span className="wow fadeIn num_circle"><strong className="r_line">3</strong></span>
                           <div className="wow fadeInRight num_text">
                              <h3>Revision becomes fun when it is given replay value</h3>
                              <p>Relearning and revising concepts feel more fun and less repetitive with a gamified approach.</p>
                           </div>
                        </li>
                        <li className="img_text">
                           <span className="wow fadeIn num_circle"><strong className="l_line">4</strong></span>
                           <div className="wow fadeInLeft num_text">
                              <h3>Students plot their educational journey by themselves</h3>
                              <p>The learning journey is personalised as the students can learn, relearn, and test themselves on their own.</p>
                           </div>
                        </li>
                        <li>
                           <span className="wow fadeIn num_circle">
                              <strong className="r_line r_line_last">5</strong></span>
                           <div className="wow fadeInRight num_text">
                              <h3>Students experience growth with a focus on learning outcomes</h3>
                              <p>An engaging study program leads students to all-round academic growth with conceptual clarity,
                                 leading to an enriched career.</p>
                           </div>
                        </li>
                     </ul>
                  </div>

                  {/* <div className="bannsec_imgtext">
<div className="left_img">
 <img src="img/reg_schol.png" alt="" />
 <span className="left_img_text">
    <strong>STEP<span className="color_ylw">app</span>  Scholarships</strong>
    Creating a Level Playing Field In Education
 </span>
</div>
<div className="wow zoomIn right_text">
<p>A Dream opportunity for students going to <strong> Grades 6th-12th to win STEPapp Scholarships </strong> and rewards ranging from ₹5000 - ₹<strong>1 Crore  </strong> from a pool of ₹<strong>50 Crores</strong>.
</p>
<p>First <strong>1 Lakh</strong> students to apply for the scholarships will get <strong> STEPapp Concepts </strong> mapped to their boards (CBSE/ICSE) Curriculum for <strong> FREE</strong>. </p>
<Link to="/" className="btn btn-pill btn-info">Register for Scholarships</Link>
</div>
</div>  */}

                  <div className="container">
                     <div className="row">
                        <div className="col-md-4">
                           <div className="wow fadeInUp stud_box" data-wow-delay=".2s">
                              <div>20,00,000+ </div>
                              Students Engaged</div>
                        </div>
                        <div className="col-md-4">
                           <div className="wow fadeInUp stud_box" data-wow-delay=".4s">
                              <div>1,500+ </div>
                              Schools Reached</div>
                        </div>
                        <div className="col-md-4">
                           <div className="wow fadeInUp stud_box" data-wow-delay=".6s">
                              <div>20,000+ </div>
                              Concepts Mastered</div>
                        </div>
                     </div>
                  </div>


                  <div className="news_blog">
                     <div className="container">
                        <h4 className="commn_heading">Turning Heads With Outcome Oriented Learning</h4>
                        <Slider {...blog_slider} className="row">
                           <div>
                              <div className="col-md-12">
                                 <div className="blog_text_area">
                                    <Link to="https://www.financialexpress.com/brandwagon/ed-tech-platforms-cash-in-on-pandemic-posts-surge-in-users-and-time-spent/1947135/" className="color_black">
                                       <div className="blog_img">
                                          <img src="img/news/01.jpg" alt="blog-image-missing" />
                                       </div>
                                       <h3>Financial Express</h3>
                                    </Link>
                                    <p>Ed-tech platforms cash in on pandemic; posts surge in users and time spent</p>
                                    <a className="btn btn-pill btn-info mt-3" href="https://www.financialexpress.com/brandwagon/ed-tech-platforms-cash-in-on-pandemic-posts-surge-in-users-and-time-spent/1947135/">Read More</a>
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div className="col-md-12">
                                 <div className="blog_text_area">
                                    <Link to="https://www.educationworld.in/stepapp-to-revolutionize-k-12-education-in-india-with-gamification-of-learning/" className="color_black">
                                       <div className="blog_img">
                                          <img src="img/news/04.jpg" alt="blog-image-missing" /></div>
                                       <h3>Education World</h3>
                                    </Link>
                                    <p>Amitabh Bachchan gave a clarion call of ‘Apna Kal Khud Banao’ to students </p>
                                    <a className="btn btn-pill btn-info mt-3" href="https://www.educationworld.in/stepapp-to-revolutionize-k-12-education-in-india-with-gamification-of-learning/">Read More</a>
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div className="col-md-12">
                                 <div className="blog_text_area">
                                    <Link to="https://www.indiatoday.in/education-today/featurephilia/story/personalized-technology-platform-is-changing-k12-education-in-india-divd-1651523-2020-03-02" className="color_black">
                                       <div className="blog_img">
                                          <img src="img/news/03.jpg" alt="blog-image-missing" /></div>
                                       <h3>India Today</h3>
                                    </Link>
                                    <p>Personalized technology platform is changing K12 education in India</p>
                                    <a className="btn btn-pill btn-info mt-3" href="https://www.indiatoday.in/education-today/featurephilia/story/personalized-technology-platform-is-changing-k12-education-in-india-divd-1651523-2020-03-02">Read More</a>
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div className="col-md-12">
                                 <div className="blog_text_area">
                                    <Link to="https://indianexpress.com/article/technology/tech-news-technology/educational-gaming-app-stepapp-launched-6166207/" className="color_black">
                                       <div className="blog_img"><img src="img/news/05.jpg" alt="blog-image-missing" /></div>
                                       <h3>Indian Express</h3>
                                    </Link>
                                    <p>Educational gaming app STEPapp launched</p>
                                    <a className="btn btn-pill btn-info mt-3" href="https://indianexpress.com/article/technology/tech-news-technology/educational-gaming-app-stepapp-launched-6166207/">Read More</a>
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div className="col-md-12">
                                 <div className="blog_text_area">
                                    <Link to="https://www.thehindu.com/news/cities/mumbai/app-takes-education-through-games-to-tribal-schools-in-state/article30143383.ece" className="color_black">
                                       <div className="blog_img"><img src="img/news/05.jpg" alt="blog-image-missing" /></div>
                                       <h3>The Hindu</h3>
                                    </Link>
                                    <p>Educational gaming app STEPapp launched</p>
                                    <a className="btn btn-pill btn-info mt-3" href="https://www.thehindu.com/news/cities/mumbai/app-takes-education-through-games-to-tribal-schools-in-state/article30143383.ece">Read More</a> </div>
                              </div>
                           </div>
                        </Slider>
                     </div>

                     <section className="blog_section">
                        <h4 className="commn_heading">Education Next: Bringing Artificial Intelligence & Adaptive Learning To The Fold</h4>
                        <div className="container">
                           <div className="content">
                              {/* <BlogRoll /> */}
                              <HomeBlog />
                           </div>
                        </div>
                     </section>
                  </div>


                  <div className="abut_section">
                     <div className="container">
                        {/* <h4 className="commn_heading">What our customers feel about us </h4>    */}
                        {/* <Carousel {...testimonials} className="row about_us">
       <div>
          <div className="col-md-12">
          <div className="blog_text_area">
                <img src="img/coma.png" alt="" />
                <p>The step up app has really made home learning easier it has made study fun. I personally had a very good experience playing the islands it cleared my basic concepts and ive started learning in a better way. And the zoom online lectures are also very good. The faculties are very good they solve our doubts n we feel just like we are in class. I would like to thank the entire pace team for the facility given to us during this lockdown.</p>
             </div>
          </div>
       </div>
       <div>
          <div className="col-md-12">
          <div className="blog_text_area">
                <img src="img/coma.png" alt="" />
                <p>STEPapp helps me learn, my basics, which I was not knowing earlier, and so every subject was becoming difficult. I feel like studying now with STEPapp for building confidence. Zoom lectures are very good, sir explained doubts very well.</p>
             </div>
          </div>
       </div>
       <div>
          <div className="col-md-12">
             <div className="blog_text_area">
                   <img src="img/coma.png" alt="" />
                   <p>We have had a few sessions of doubt clearing on chemistry. Zoom app has been very helpful in making the interactive sessions very efficient and fruitful. The voice audio as well as the visual display was clear in making the sessions helpful.</p>
                   <p>Step app :- We have played a number of topics of physics,chemistry and maths I can say that step app has truly helped me strengthen my basic concepts before dwelling into advanced ones. The features and the graphics of the app are very grasping and it kept me engrossed in the topic and strengthen my base. The explanation and the demonstration via visual graphics had developed and interest in me for the chapter. The immediate explanation of any mistake made by while playing the tests was very helpful. Step app has truly made me learn while playing</p>
                   <h4>Aasneh Prasad*- Zoom app</h4>
                </div>
          </div>
       </div>
       <div>
          <div className="col-md-12">
          <div className="blog_text_area">
                <img src="img/coma.png" alt="" />
                <p>Dhruv Bhagchandani- STEPapp is the perfect solution for us to learn from home and continue our syllabus. The animations ensure that we understand the concept well and the quiz taken at the end of an “island” are really beneficial for us to know how much we’ve understood and how much we need to revise. I am extremely grateful to Pace for STEPapp </p>
                <h4>DADAR</h4>
             </div>
          </div>
       </div>
    </Carousel>   */}


                        <div className="">
                           <div className="container">
                              <h4 className="commn_heading">What the Industry Leaders have to say about STEPapp</h4>
                              {/* <Video/> */}
                              <div>
                                 <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoId} onClose={() => this.setState({ isOpen: false })} />
                                 {/* <button onClick={this.openModal}>Open</button> */}
                              </div>
                              <Slider {...our_partner} className="row patnar-slide our_team_sec">
                                 <div>
                                    <div className="col-md-12">
                                       <div className="blog_text_area">
                                          {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='y-AxlATegLY' onClose={() => this.setState({isOpen: false})} /> */}
                                          {/* <button onClick={()=>this.openModal('y-AxlATegLY')}>  */}


                                          <ReactPlayer className="react_player"
                                             url={this.state.isOpen === false ? 'https://youtu.be/AlZlUA5EsdE' : ''}
                                             onPlay={() => this.onPlay('AlZlUA5EsdE')}
                                             width='100%' height='100%' controls='false' />
                                          {/* <img src="img/p3.png" alt="" /> */}
                                          <h3>Mr Deepak S Parekh HDFC Limited Chairman</h3>
                                          {/* </button> */}
                                       </div>
                                    </div>
                                 </div>

                                 <div>
                                    <div className="col-md-12">
                                       <div className="blog_text_area">
                                          {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='oiO41WdfOw8' onClose={() => this.setState({isOpen: false})} /> */}
                                          {/* <button onClick={()=>this.openModal('oiO41WdfOw8')}>Open</button> */}
                                          <ReactPlayer className="react_player"
                                             url={this.state.isOpen === false ? 'https://youtu.be/cvrudbC-RH4' : ''}
                                             width='100%' height='100%' controls='true'
                                             onPlay={() => this.onPlay('cvrudbC-RH4')} />
                                          <h3>STEPapp Backed By Amitabh Bachchan</h3>
                                       </div>
                                    </div>
                                 </div>

                                 <div>
                                    <div className="col-md-12">
                                       <div className="blog_text_area">
                                          <ReactPlayer className="react_player"
                                             url={this.state.isOpen === false ? 'https://youtu.be/7uevQ8RDWyc' : ''}
                                             onPlay={() => this.onPlay('7uevQ8RDWyc')}
                                             width='100%' height='100%' controls='true' />
                                          {/* <img src="img/p3.png" alt="" /> */}
                                          <h3>Mr Ramakrishnan Ramamurthi | CEO of Polycab | Talks about STEPapp</h3>
                                       </div>
                                    </div>
                                 </div>

                                 <div>
                                    <div className="col-md-12">
                                       <div className="blog_text_area">
                                          <ReactPlayer className="react_player"
                                             url={this.state.isOpen === false ? 'https://youtu.be/hyCD_UKDtD8' : ''}
                                             onPlay={() => this.onPlay('hyCD_UKDtD8')}
                                             width='100%' height='100%' controls='true' />
                                          <h3>STEPapp | Piyush Pandey | Chief Creative Officer Worldwide and Executive Chairman India, Ogilvy.</h3>
                                       </div>
                                    </div>
                                 </div>
                                 <div>
                                    <div className="col-md-12">
                                       <div className="blog_text_area">
                                          <ReactPlayer className="react_player"
                                             url={this.state.isOpen === false ? 'https://youtu.be/nu6Y-nA2gvk' : ''}
                                             onPlay={() => this.onPlay('nu6Y-nA2gvk')}
                                             width='100%' height='100%' controls='true' />
                                          <h3>STEPapp | Mr Sainath Durge | BMC Education Committee Members</h3>
                                       </div>
                                    </div>
                                 </div>

                                 <div>
                                    <div className="col-md-12">
                                       <div className="blog_text_area">
                                          <ReactPlayer className="react_player"
                                             url={this.state.isOpen === false ? 'https://youtu.be/8t9QWWGi9vA' : ''}
                                             onPlay={() => this.onPlay('8t9QWWGi9vA')}
                                             width='100%' height='100%' controls='true' />
                                          <h3>STEPapp | Ms Sonia Golani | Author</h3>

                                       </div>
                                    </div>
                                 </div>

                              </Slider>

                           </div>
                        </div>
                     </div>
                  </div>


                  <div className="container home_partner">
                     <h4 className="commn_heading">Our Partners</h4>
                     <Slider {...our_partner} className="row">
                        <div>
                           <div className="col-md-12">
                              <img src="img/p-1.jpg" alt="partner logo" />
                           </div>
                        </div>
                        <div>
                           <div className="col-md-12">
                              <img src="img/p-2.jpg" alt="partner logo" />
                           </div>
                        </div>
                        <div>
                           <div className="col-md-12">
                              <img src="img/p-4.jpg" alt="partner logo" />
                           </div>
                        </div>
                        <div>
                           <div className="col-md-12">
                              <img src="img/p-5.jpg" alt="partner logo" />
                           </div>
                        </div>
                     </Slider>
                  </div>


                  <div className="techngy">
                     <div className="tech_text">
                        <h3><strong> TEACH-<span>NOLOGY</span></strong></h3>
                        <p>Combining deep and extensive teaching experience with cutting-edge technology.</p>
                        {/* <Link to="/" className="blue_btn">Download The App</Link> */}
                        <div className="app_btn">
                           <a href="https://apps.apple.com/in/app/step-game-to-learn/id1472230553">
                              <img src="img/apl_ply.png" alt="apple play store" />
                           </a>
                           <a href="https://play.google.com/store/apps/details?id=com.eduisfun.stepapplite">
                              <img src="img/ggl_ply.png" alt="google play store" />
                           </a>
                        </div>
                     </div>
                  </div>
               </div>

            </SiteLayout></>
      )
   }
}
